<template>
<b-container>
  <b-row>
    <b-form class="w-100">
      <b-form-group
      id="input-loc-translation-name"
      label="Naam locatie">
        <b-form-input
            id="loc-translation-name"
            placeholder="Naam locatie invoeren..."
            type="text"
            v-model="form.name">
        </b-form-input>
      </b-form-group>
      <b-form-group
          id="input-loc-translation-description"
          label="Omschrijving locatie">
        <b-form-textarea
            id="loc-translation-description"
            placeholder="Omschrijving locatie invoeren..."
            rows="7"
            v-model="form.description">
        </b-form-textarea>
      </b-form-group>

    </b-form>
  </b-row>
  <b-row><b-button class="w-50 btn-info" @click="updateLocationTranslation">Wijzig</b-button><b-button class="w-50 btn-danger" @click="deleteLocationTranslation">Verwijder</b-button></b-row>
</b-container>
</template>

<script>
import axios from "axios";
import config from '../../public/config/api.json';
let configApi = null;
if(config.is_production) {
  configApi = config.production;
} else {
  configApi = config.test;
}

export default {
name: "LocationTranslation",
  props: ['translation'],
  data() {
    return {
      form: {
        name: '',
        description: ''
      }
    }
  },
  methods: {
    deleteLocationTranslation() {
      if(confirm('Deze vertaling verwijderen?')) {
        axios.delete(configApi.api_url + 'admin/spottranslation/' + this.translation.id)
            .then((res) => {
              if(res){
                location.reload();
              }
            })
      }
    },
    updateLocationTranslation() {
      let spotTranslationUpdate = {
        "name" : encodeURI(this.form.name),
        "description" : encodeURI(this.form.description)
      }
      axios.put(configApi.api_url + 'admin/spottranslation/' + this.translation.id, spotTranslationUpdate)
          .then((res) => {
            if(res) {
              location.reload();
            }
          })
      }
  },
  created() {
    this.form.name = decodeURI(this.translation.name);
    this.form.description = decodeURI(this.translation.description);
  }
}
</script>

<style scoped>

</style>
