<template>
    <b-container>
        <b-row><h1>Bewerk Onderwerp {{ idObject }}</h1></b-row>
        <b-row cols="2" class="w-75 mx-auto">
            <b-col class="w-50">
                <b-form class="w-100 mx-auto">
                    <b-form-group
                        class="w-100"
                        id="input-object-objectnr"
                        label="Objectnummer onderwerp">
                        <b-form-input
                            id="object-objectnr"
                            v-model="objectData.objectnr"
                            type="text"
                            placeholder="Objectnummer voor onderwerp">
                        </b-form-input>
                    </b-form-group>
                    <b-form-group
                        class="w-100"
                        id="input-object-image"
                        label="Afbeelding onderwerp">
                        <b-form-input
                            id="object-image"
                            v-model="objectData.image"
                            type="text"
                            placeholder="Afbeelding voor onderwerp">
                        </b-form-input>
                    </b-form-group>
                    <b-button @click="updateObject" class="w-100 btn-info">Wijzig</b-button>
                    <!--                <b-button @click="deleteobject" class="w-50 btn-danger">Verwijder</b-button>-->
                </b-form>
            </b-col>
            <b-col class="w-50">
                <div class="w-75 mx-auto">
                    <img v-if="objectData.image !== ''" :src="'https://media.nbm-info.nl/files/medium/' + objectData.image" class="imagepreview">
                </div>
            </b-col>
        </b-row>
        <hr class="w-75 mx-auto">
        <b-row class="w-75 mx-auto mt-2">
            <h2>Vertalingen</h2>
        </b-row>
        <b-row class="w-75 mx-auto mt-2">
            <b-button v-b-modal.modal-translation>Nieuwe vertaling</b-button>
        </b-row>
        <b-row>
            <b-tabs v-model="tabIndex" class="w-75 mx-auto" card>
                <b-tab v-bind:key="translation.id" v-for="translation in objectData.translations"
                       :title="translation.code_language">
                    <ObjectTranslation
                        v-bind:translation="translation"></ObjectTranslation>
                </b-tab>
            </b-tabs>
        </b-row>
        <b-modal id="modal-translation" hide-footer>
            <b-form class="w-100">
                <b-form-group
                    id="input-loc-translation-lang-nw"
                    label="Taal">
                    <b-form-select v-model="translationFormData.idLang">
                        <b-form-select-option :key="language.description" v-for="language in languageData" :value="language.id">{{ language.description }}</b-form-select-option>
                    </b-form-select>
                </b-form-group>
                <b-form-group
                    id="input-object-translation-title-nw"
                    label="Titel onderwerp">
                    <b-form-input
                        id="object-translation-title-nw"
                        placeholder="Titel onderwerp invoeren..."
                        type="text"
                        v-model="translationFormData.title"
                    >
                    </b-form-input>
                </b-form-group>
                <b-form-group
                    id="input-object-translation-description-nw"
                    label="Omschrijving onderwerp">
                    <b-form-textarea
                        id="object-translation-description-nw"
                        placeholder="Omschrijving onderwerp invoeren..."
                        rows="7"
                        v-model="translationFormData.description"
                    >
                    </b-form-textarea>
                </b-form-group>
                <b-button @click="newObjectTranslation">Bewaar</b-button>
            </b-form>

        </b-modal>
    </b-container>
</template>

<script>
import axios from 'axios';
// import router from '@/router';
import config from '../../public/config/api.json';
// import {getUserInfo} from "@/utils/auth";
import ObjectTranslation from "@/components/ObjectTranslation";
import router from "@/router";
let configApi = null;
if(config.is_production) {
    configApi = config.production;
} else {
    configApi = config.test;
}

export default {
    name: "Object",
    props: ['idObject'],
    components: {
        ObjectTranslation
    },
    data() {
        return {
            objectData: {},
            languageData: [],
            tabIndex:0,
            translationFormData: {
                idLang: null,
                title: '',
                description: '',
                audio: ''
            }
        }
    },
    methods: {
        updateObject() {
            // let dataToSend = new FormData();
            let updateData = {
                "objectnr": this.objectData.objectnr,
                "image": this.objectData.image
            };
            // dataToSend.append('data', updateData);
            axios.put(configApi.api_url + 'admin/object/' + this.idObject, updateData)
                .then((res) => {
                    // console.log(res);
                    // router.push('/locations');
                    if(res) {
                        location.reload();
                    }
                })
        },
        deleteObject() {
            if(confirm('Weet je het zeker? \nAlle vertalingen en koppelingen worden verwijderd!')) {
                axios.delete(configApi.api_url + 'admin/object/' + this.idObject)
                    .then((res) => {
                        if(res) {
                            router.push('/objects');
                        }
                    })
            }
        },
        newObjectTranslation() {
            let newObjectTransData = {
                "id_lang": this.translationFormData.idLang,
                "title": encodeURI(this.translationFormData.title),
                "description": encodeURI(this.translationFormData.description),
                "audio": this.translationFormData.audio
            }
            axios.post(configApi.api_url + 'admin/objecttranslation/' + this.idObject, newObjectTransData)
                .then((transObjectResult) => {
                    if(transObjectResult) {
                        // router.push('/location/' + this.idLoc);
                        location.reload();
                    }
                })
        }
    },
    created() {
        axios.get(configApi.api_url + 'admin/object/' + this.idObject )
            .then((objectResult) => {
                this.objectData = objectResult.data;
                // console.log(JSON.stringify(this.objectData));
                //   this.tabIndex = 0;
            })
            .catch((err) => {
                console.log('object error: ' + err);
            });
        axios.get(configApi.api_url + 'admin/languages')
            .then((langResult) => {
                this.languageData = langResult.data;
            })
            .catch((err) => {
                console.log('languages error: ' + err);
            });
    }
}
</script>

<style scoped>
.imagepreview {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
</style>
