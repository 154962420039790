<template>
    <b-navbar toggleable="lg" variant="primary" type="dark">
        <b-navbar-brand href="#"><img src="/assets/logo-diap-500.png" width="140" height="auto" class="mr-4"> {{ fullName }}</b-navbar-brand>
        <b-navbar-toggle target="getoggeld"></b-navbar-toggle>
        <b-collapse id="getoggeld" is-nav>
            <b-navbar-nav>
                <b-nav-item to="/">Home</b-nav-item>
                <b-nav-item to="/objects">Onderwerpen</b-nav-item>
                <b-nav-item to="/locations">Locaties</b-nav-item>
              <b-nav-item to="/themes">Thema's</b-nav-item>
              <b-nav-item to="/connections">Koppelingen</b-nav-item>
            </b-navbar-nav>
            <b-navbar-nav class="ml-auto">
                <b-nav-item to="/" @click="logOut"><b-icon-box-arrow-right></b-icon-box-arrow-right></b-nav-item>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</template>

<script>
import {getUserInfo, logoutUser} from "@/utils/auth";
// import router from "@/router";

export default {
    name: "Nav",
    data() {
        return {
            fullName: ''
        }
    },
    methods: {
        logOut: function() {
            logoutUser();
            // router.push('/');
            window.location.reload();
        }
    },
    created() {
        let userData = getUserInfo();
        if(userData.fullname) {
            this.fullName = userData.fullname;
        }
    }
}
</script>

<style scoped>

</style>
