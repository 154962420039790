<template>
<b-container>
  <b-row><h1>Bewerk Locatie {{ idLoc }}</h1></b-row>
  <b-row cols="2" class="w-75 mx-auto">
      <b-col class="w-50">
          <b-form class="w-100">
              <b-form-group
                  class="w-100"
                  id="input-location-code"
                  label="Code Locatie">
                  <b-form-input
                      id="location-code"
                      v-model="locationData.code"
                      type="text"
                      placeholder="Code voor locatie">
                  </b-form-input>
              </b-form-group>
              <b-form-group
                  class="w-100"
                  id="input-location-color"
                  label="Kleur locatie">
                  <b-form-input
                      id="location-color"
                      v-model="locationData.color"
                      type="color">
                  </b-form-input>
              </b-form-group>
              <b-form-group
                  class="w-100"
                  id="input-location-thumb"
                  label="Afbeelding locatie">
                  <b-form-input
                      id="location-thumb"
                      v-model="locationData.thumb"
                      type="text"
                      placeholder="Afbeelding voor locatie">
                  </b-form-input>
              </b-form-group>
              <b-button @click="updateLocation" class="w-100 btn-info">Wijzig</b-button>
              <b-button @click="deleteLocation" class="w-100 btn-danger">Verwijder</b-button>
          </b-form>
      </b-col>
      <b-col class="w-50">
          <div class="w-75 mx-auto">
              <img v-if="locationData.thumb !== ''" :src="'https://media.nbm-info.nl/files/medium/' + locationData.thumb" class="imagepreview">
          </div>
      </b-col>
  </b-row>
  <hr class="w-75 mx-auto">
  <b-row class="w-75 mx-auto mt-2">
    <h2>Vertalingen</h2>
  </b-row>
  <b-row class="w-75 mx-auto mt-2">
    <b-button v-b-modal.modal-translation>Nieuwe vertaling</b-button>
  </b-row>
  <b-row>
    <b-tabs v-model="tabIndex" class="w-75 mx-auto" card>
      <b-tab v-bind:key="translation.id" v-for="translation in locationData.translations"
             :title="translation.code_language">
        <LocationTranslation
            v-bind:translation="translation"></LocationTranslation>
      </b-tab>
    </b-tabs>
  </b-row>
  <b-modal id="modal-translation" hide-footer>
    <b-form class="w-100">
      <b-form-group
        id="input-loc-translation-lang-nw"
        label="Taal">
        <b-form-select v-model="translationFormData.idLang">
          <b-form-select-option :key="language.description" v-for="language in languageData" :value="language.id">{{ language.description }}</b-form-select-option>
        </b-form-select>
      </b-form-group>
      <b-form-group
          id="input-loc-translation-name-nw"
          label="Naam locatie">
        <b-form-input
            id="loc-translation-name-nw"
            placeholder="Naam locatie invoeren..."
            type="text"
            v-model="translationFormData.name"
        >
        </b-form-input>
      </b-form-group>
      <b-form-group
          id="input-loc-translation-description-nw"
          label="Omschrijving locatie">
        <b-form-textarea
            id="loc-translation-description-nw"
            placeholder="Omschrijving locatie invoeren..."
            rows="7"
            v-model="translationFormData.description"
        >
        </b-form-textarea>
      </b-form-group>
      <b-button @click="newLocationTranslation">Bewaar</b-button>
    </b-form>

  </b-modal>
</b-container>
</template>

<script>
import axios from 'axios';
import router from '@/router';
import config from '../../public/config/api.json';
// import {getUserInfo} from "@/utils/auth";
import LocationTranslation from "@/components/LocationTranslation";
let configApi = null;
if(config.is_production) {
  configApi = config.production;
} else {
  configApi = config.test;
}

export default {
name: "Location",
  props: ['idLoc'],
  components: {
    LocationTranslation
  },
  data() {
    return {
      locationData: {},
      languageData: [],
      tabIndex:0,
      translationFormData: {
        idLang: null,
        name: '',
        description: ''
      }
    }
  },
  methods: {
    updateLocation() {
        // let dataToSend = new FormData();
        let updateData = {
            "code": this.locationData.code,
            "color": this.locationData.color,
            "thumb": this.locationData.thumb
        };
        // dataToSend.append('data', updateData);
        axios.put(configApi.api_url + 'admin/spot/' + this.idLoc, updateData)
        .then((res) => {
            // console.log(res);
            // router.push('/locations');
          if(res) {
            location.reload();
          }
        })
    },
    deleteLocation() {
      if(confirm('Weet je het zeker? \nAlle vertalingen en koppelingen worden verwijderd!')) {
        axios.delete(configApi.api_url + 'admin/spot/' + this.idLoc)
        .then((res) => {
          if(res) {
            router.push('/locations');
          }
        })
      }
    },
    newLocationTranslation() {
        let newLocTransData = {
          "id_lang": this.translationFormData.idLang,
          "name": encodeURI(this.translationFormData.name),
          "description": encodeURI(this.translationFormData.description)
        }
        axios.post(configApi.api_url + 'admin/spottranslation/' + this.idLoc, newLocTransData)
      .then((transLocResult) => {
        if(transLocResult) {
          // router.push('/location/' + this.idLoc);
          location.reload();
        }
      })
    }
  },
  created() {
    axios.get(configApi.api_url + 'admin/spot/' + this.idLoc )
    .then((locationResult) => {
      this.locationData = locationResult.data;
      // console.log(JSON.stringify(this.locationData));
      //   this.tabIndex = 0;
    })
    .catch((err) => {
      console.log('location error: ' + err);
    });
    axios.get(configApi.api_url + 'admin/languages')
    .then((langResult) => {
      this.languageData = langResult.data;
    })
    .catch((err) => {
      console.log('languages error: ' + err);
    });
  }
}
</script>

<style scoped>
.imagepreview {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
</style>
