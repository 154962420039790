<template>
  <b-container>
    <b-row>
      <b-form class="w-100">
        <b-form-group
            id="input-theme-translation-name"
            label="Naam thema">
          <b-form-input
              id="theme-translation-name"
              placeholder="Naam thema invoeren..."
              type="text"
              v-model="form.name">
          </b-form-input>
        </b-form-group>
        <b-form-group
            id="input-theme-translation-description"
            label="Omschrijving thema">
          <b-form-textarea
              id="loc-thema-description"
              placeholder="Omschrijving thema invoeren..."
              rows="7"
              v-model="form.description">
          </b-form-textarea>
        </b-form-group>
      </b-form>
    </b-row>
    <b-row><b-button class="w-50 btn-info" @click="updateThemeTranslation">Wijzig</b-button><b-button class="w-50 btn-danger" @click="deleteThemeTranslation">Verwijder</b-button></b-row>
  </b-container>

</template>

<script>
  import axios from "axios";
  import config from '../../public/config/api.json';
  let configApi = null;
  if(config.is_production) {
    configApi = config.production;
  } else {
    configApi = config.test;
  }

  export default {
    name: "ThemeTranslation",
    props: ['translation'],
    data() {
      return {
        form: {
          name: '',
          description: ''
        }
      }
    },
    methods: {
      deleteThemeTranslation() {
        if(confirm('Deze vertaling verwijderen?')) {
          axios.delete(configApi.api_url + 'admin/themetranslation/' + this.translation.id)
              .then((res) => {
                if(res){
                  location.reload();
                }
              })
        }
      },
      updateThemeTranslation() {
        let themeTranslationUpdate = {
          "name" : encodeURI(this.form.name),
          "description" : encodeURI(this.form.description)
        }
        axios.put(configApi.api_url + 'admin/themetranslation/' + this.translation.id, themeTranslationUpdate)
          .then((res) => {
            if(res) {
              location.reload();
            }
          })
      }
    },
    created() {
      this.form.name = decodeURI(this.translation.name);
      this.form.description = decodeURI(this.translation.description);
    }
  }
</script>

<style scoped>

</style>
