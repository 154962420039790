<template>
  <b-container>
    <b-row><h1>Bewerk Thema {{ idTheme }}</h1></b-row>
    <b-row cols="2" class="w-75 mx-auto">
        <b-col class="w-50">
            <b-form class="w-100">
                <b-form-group
                    class="w-100"
                    id="input-thema-naam"
                    label="Naam (generiek) ">
                    <b-form-input
                        id="thema-naam"
                        v-model="themeData.name"
                        type="text"
                        placeholder="Naam voor thema">
                    </b-form-input>
                </b-form-group>
                <b-form-group
                    class="w-100"
                    id="input-theme-thumb"
                    label="Thumbnail voor thema">
                    <b-form-input
                        id="theme-thumb"
                        v-model="themeData.thumb"
                        type="text">
                    </b-form-input>
                </b-form-group>
                <b-button @click="updateTheme" class="w-50 btn-info">Wijzig</b-button>
                <b-button @click="deleteTheme" class="w-50 btn-danger">Verwijder</b-button>
            </b-form>
        </b-col>
        <b-col class="w-50">
            <div class="w-75 mx-auto">
                <img v-if="themeData.thumb !== ''" :src="'https://media.nbm-info.nl/files/medium/' + themeData.thumb" class="imagepreview">
            </div>
        </b-col>
    </b-row>
    <hr class="w-75 mx-auto">
    <b-row class="w-75 mx-auto mt-2">
      <h2>Vertalingen</h2>
    </b-row>
    <b-row class="w-75 mx-auto mt-2">
      <b-button v-b-modal.modal-translation>Nieuwe vertaling</b-button>
    </b-row>
    <b-row>
      <b-tabs v-model="tabIndex" class="w-75 mx-auto" card>
        <b-tab v-bind:key="translation.id" v-for="translation in themeData.translations"
               :title="translation.code_language">
          <ThemeTranslation
              v-bind:translation="translation"></ThemeTranslation>
        </b-tab>
      </b-tabs>
    </b-row>
    <b-modal id="modal-translation" hide-footer>
      <b-form class="w-100">
        <b-form-group
            id="input-theme-translation-lang-nw"
            label="Taal">
          <b-form-select v-model="translationFormData.idLang">
            <b-form-select-option :key="language.description" v-for="language in languageData" :value="language.id">{{ language.description }}</b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group
            id="input-theme-translation-name-nw"
            label="Naam thema">
          <b-form-input
              id="theme-translation-name-nw"
              placeholder="Naam thema invoeren..."
              type="text"
              v-model="translationFormData.name"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
            id="input-theme-translation-description-nw"
            label="Omschrijving thema">
          <b-form-textarea
              id="theme-translation-description-nw"
              placeholder="Omschrijving thema invoeren..."
              rows="7"
              v-model="translationFormData.description"
          >
          </b-form-textarea>
        </b-form-group>
        <b-button @click="newThemeTranslation">Bewaar</b-button>
      </b-form>

    </b-modal>
  </b-container>
</template>

<script>
  import axios from "axios";
  import router from "@/router";
  import config from '../../public/config/api.json';
  // import {getUserInfo} from "@/utils/auth";
  import ThemeTranslation from "@/components/ThemeTranslation";
  let configApi = null;
  if(config.is_production) {
    configApi = config.production;
  } else {
    configApi = config.test;
  }

  export default {
    name: "Theme",
    components: {ThemeTranslation},
    props: ['idTheme'],
    data() {
      return {
        themeData: {},
        languageData: [],
        tabIndex:0,
        translationFormData: {
          idLang: null,
          name: '',
          description: ''
        }
      }
    },
    methods: {
      updateTheme() {
        //
        let updateData = {
          "name": this.themeData.name,
          "thumb": this.themeData.thumb
        };
        axios.put(configApi.api_url + 'admin/theme/' + this.idTheme, updateData)
            .then((res) => {
              // console.log(res);
              // router.push('/locations');
              if(res) {
                location.reload();
              }
            })
      },
      deleteTheme() {
        if(confirm('Weet je het zeker? \nAlle vertalingen en koppelingen worden verwijderd!')) {
          axios.delete(configApi.api_url + 'admin/theme/' + this.idTheme)
              .then((res) => {
                if(res) {
                  router.push('/themes');
                }
              })
        }
      },
      newThemeTranslation() {
        let newThemeTransData = {
          "id_lang": this.translationFormData.idLang,
          "name": encodeURI(this.translationFormData.name),
          "description": encodeURI(this.translationFormData.description)
        }
        axios.post(configApi.api_url + 'admin/themetranslation/' + this.idTheme, newThemeTransData)
            .then((transThemeResult) => {
              if(transThemeResult) {
                // router.push('/location/' + this.idLoc);
                location.reload();
              }
            })
      }
    },
    created() {
      axios.get(configApi.api_url + 'admin/theme/' + this.idTheme )
          .then((themeResult) => {
            this.themeData = themeResult.data;
            // console.log(JSON.stringify(this.locationData));
            //   this.tabIndex = 0;
          })
          .catch((err) => {
            console.log('theme error: ' + err);
          });
      axios.get(configApi.api_url + 'admin/languages')
          .then((langResult) => {
            this.languageData = langResult.data;
          })
          .catch((err) => {
            console.log('languages error: ' + err);
          });
    }
  }
</script>

<style scoped>
.imagepreview {
    max-width: 100%;
    object-fit: contain;
}
</style>
