<template>
  <b-container>
    <b-row><h1>Koppelingen</h1></b-row>
    <b-row><b-btn v-b-modal.modal-new-connection class="btn-info">Nieuwe koppeling</b-btn> </b-row>
    <b-row>
      <b-col class="w-50">
        <b-form-group
            class="w-100"
          label="Locatie"
            label-for="filterLocation"
        >
          <b-form-select id="filterLocation" v-model="locationSelected" @change="getConnectionList">
            <b-form-select-option value=null disabled>-- Selecteer een locatie --</b-form-select-option>
            <b-form-select-option :key="location.code" v-for="location in locationList" :value="location.id">{{ location.code }}</b-form-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col class="w-50">
        <b-form-group
            class="w-100"
        label="Thema"
            label-for="filterTheme"
        >
          <b-form-select id="filterTheme" v-model="themeSelected" @change="getConnectionList">
            <b-form-select-option value=null disabled>-- Selecteer een thema --</b-form-select-option>
            <b-form-select-option :key="theme.name" v-for="theme in themeList" :value="theme.id">{{ theme.name }}</b-form-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-table
          id="connectionlist"
          fixed
          striped
          hover
          :items="filteredConnectionList"
          :current-page="currentPage"
          :per-page="perPage"
          :fields="fields"
          @row-clicked="onConnectionSelect"
      >
      <template #cell(actions)="row">
        <b-button size="sm" class="btn-danger" @click="deleteConnection(row.item.id)">
          Wissen
        </b-button>
      </template>
      </b-table>
      <b-pagination
          v-model="currentPage"
          :total-rows="connectionsLength"
          :per-page="perPage"
          aria-controls="connectionlist"
      ></b-pagination>
    </b-row>
    <b-modal id="modal-update-connection" hide-footer>
      <b-form class="w-100">
        <b-form-group
            id="input-conn-label-upd"
            label="Label koppeling">
          <b-form-input
              id="conn-label-upd"
              placeholder="Label koppeling invoeren..."
              type="text"
              v-model="updateFormData.label"
          >
          </b-form-input>
        </b-form-group>
        <b-button class="btn-info" @click="updateConnection">Wijzig</b-button>
      </b-form>
    </b-modal>
    <b-modal id="modal-new-connection" hide-footer>
      <h3>Locatie: {{ locationSelected }}</h3>
      <h3>Thema: {{ themeSelected }}</h3>
      <b-form class="w-100">
        <b-form-group
            id="input-conn-object-new"
            label="Object (priref) koppeling">
          <b-form-input
              id="conn-object-new"
              placeholder="Object (priref) invoeren..."
              type="text"
              v-model="newFormData.id_object"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
            id="input-conn-label-new"
            label="Label koppeling">
          <b-form-input
              id="conn-label-new"
              placeholder="Label koppeling invoeren..."
              type="text"
              v-model="newFormData.label"
          >
          </b-form-input>
        </b-form-group>
        <b-button class="btn-info" @click="newConnection">Bewaar</b-button>
      </b-form>
    </b-modal>
  </b-container>
</template>

<script>
  import axios from "axios";
  import config from '../../public/config/api.json';
  // import {getUserInfo} from "@/utils/auth";
  let configApi = null;
  if(config.is_production) {
    configApi = config.production;
  } else {
    configApi = config.test;
  }
  export default {
    name: "Connections",
    data() {
      return {
        filteredConnectionList: [],
        perPage: 10,
        currentPage: 1,
        connectionsLength: 0,
        fields: [
          {
            key: 'id',
            label: 'id'
          },
          {
            key: 'priref',
            label: 'priref'
          },
          {
            key: 'objectnr',
            label: 'object nummer'
          },
          {
            key: 'label',
            label: 'label'
          },
          {
            key: 'actions',
            label: 'akties'
          }
        ],
        locationList: [],
        themeList: [],
        locationSelected: null,
        themeSelected: null,
        updateFormData: {
          id: 0,
          label: ''
        },
        newFormData: {
          id_object: null,
          label: ''
        }
      }
    },
    methods: {
      getConnectionList() {
        console.log('getConnectionList()');
        if(this.locationSelected !== null && this.themeSelected !== null) {
          // console.log('we gaan filteren! location: ' + this.locationSelected + ', theme: ' + this.themeSelected);
          axios.get(configApi.api_url + 'admin/connections/' + this.locationSelected + '/' + this.themeSelected)
          .then((conResult) => {
            this.filteredConnectionList = conResult.data;
            this.connectionsLength = conResult.data.length;
          })
        } else {
          console.log('we gaan NIET filteren! location: ' + this.locationSelected + ', theme: ' + this.themeSelected);
        }
      },
      newConnection() {
        if(this.locationSelected !== null && this.themeSelected !== null) {
          let newData = {
            "id_location": this.locationSelected,
            "id_theme": this.themeSelected,
            "id_object": this.newFormData.id_object,
            "label": this.newFormData.label
          };
          axios.post(configApi.api_url + 'admin/connection', newData)
          .then(newConnResult => {
            if(newConnResult) {
              this.getConnectionList();
              this.$bvModal.hide('modal-new-connection');
            } else {
              console.log('error newConnectionQuery')
            }
          })
        }
      },
      onConnectionSelect(connectionSelected) {
        this.updateFormData.id = connectionSelected.id;
        this.updateFormData.label = connectionSelected.label;
        this.$bvModal.show('modal-update-connection');
        console.log('Iets geselecteerd!');
      },
      updateConnection() {
        //
        let updateData = {
          "label": this.updateFormData.label,
        };
        axios.put(configApi.api_url + 'admin/connection/' + this.updateFormData.id, updateData)
            .then((res) => {
              if(res) {
                this.updateFormData.id = 0;
                this.updateFormData.label = '';
                // location.reload();
                this.$bvModal.hide('modal-update-connection');
                this.getConnectionList();
              }
            })

      },
      deleteConnection(idIn) {
        if(confirm('Koppeling verwijderen.\nZeker weten?')) {
          console.log('koppeling: ' + idIn + ' wordt verwijderd');
          axios.delete(configApi.api_url + 'admin/connection/' + idIn)
          .then((delConResult) => {
            if(delConResult) {
              this.getConnectionList();
            }
          })
        }
      }
    },
    created() {
      // get all locations
      axios.get(configApi.api_url + 'admin/spots')
      .then((locResult) => {
        this.locationList = locResult.data;
      })

      // get all themes
      axios.get(configApi.api_url + 'admin/themes')
      .then((themesResult) => {
        this.themeList = themesResult.data;
      })

    }
  }
</script>

<style scoped>

</style>
