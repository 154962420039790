<template>
    <b-container>
        <b-row>
            <b-form class="w-100">
                <b-form-group
                    id="input-obj-translation-title"
                    label="Titel">
                    <b-form-input
                        id="obj-translation-title"
                        placeholder="Titel onderwerp invoeren..."
                        type="text"
                        v-model="form.title">
                    </b-form-input>
                </b-form-group>
                <b-form-group
                    id="input-obj-translation-description"
                    label="Omschrijving onderwerp">
                    <b-form-textarea
                        id="obj-translation-description"
                        placeholder="Omschrijving onderwerp invoeren..."
                        rows="7"
                        v-model="form.description">
                    </b-form-textarea>
                </b-form-group>
                <b-form-group
                    id="input-obj-translation-audio"
                    label="Audio bestand">
                    <b-form-input
                        id="obj-translation-audio"
                        placeholder="Audio bestand onderwerp invoeren..."
                        type="text"
                        v-model="form.audio">
                    </b-form-input>
                </b-form-group>

            </b-form>
        </b-row>
        <b-row><b-button class="w-50 btn-info" @click="updateObjectTranslation">Wijzig</b-button><b-button class="w-50 btn-danger" @click="deleteObjectTranslation">Verwijder</b-button></b-row>
    </b-container>
</template>

<script>
import axios from "axios";
import config from '../../public/config/api.json';
let configApi = null;
if(config.is_production) {
    configApi = config.production;
} else {
    configApi = config.test;
}

export default {
    name: "ObjectTranslation",
    props: ['translation'],
    data() {
        return {
            form: {
                title: '',
                description: '',
                audio: ''
            }
        }
    },
    methods: {
        deleteObjectTranslation() {
            if(confirm('Deze vertaling verwijderen?')) {
                axios.delete(configApi.api_url + 'admin/objecttranslation/' + this.translation.id)
                    .then((res) => {
                        if(res){
                            location.reload();
                        }
                    })
            }
        },
        updateObjectTranslation() {
            let objectTranslationUpdate = {
                "title" : encodeURI(this.form.title),
                "description" : encodeURI(this.form.description),
                "audio" : this.form.audio
            }
            axios.put(configApi.api_url + 'admin/objecttranslation/' + this.translation.id, objectTranslationUpdate)
                .then((res) => {
                    if(res) {
                        location.reload();
                    }
                })
        }
    },
    created() {
        this.form.title = decodeURI(this.translation.title);
        this.form.description = decodeURI(this.translation.description);
        this.form.audio = this.translation.audio
    }
}
</script>

<style scoped>

</style>
