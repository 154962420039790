import Vue from 'vue';
import VueRouter from 'vue-router';
import { isLoggedIn } from "@/utils/auth";
import Home from "@/views/Home";
import Login from "@/views/Login";
import Locations from "@/views/Locations";
import Location from "@/views/Location";
import Themes from "@/views/Themes";
import Theme from "@/views/Theme";
import Connections from "@/views/Connections";
import Objects from "@/views/Objects";
import Object from '@/views/Object';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: "/objects",
        name: "Objects",
        component: Objects
    },
    {
        path: "/object/:idObject",
        name: "Object",
        component: Object,
        props: true
    },
    {
        path: '/locations',
        name: 'Locations',
        component: Locations
    },
    {
        path: '/location/:idLoc',
        name: 'Location',
        component: Location,
        props: true
    },
    {
        path: '/themes',
        name: 'Themes',
        component: Themes
    },
    {
        path: '/theme/:idTheme',
        name: 'Theme',
        component: Theme,
        props: true
    },
    {
        path: '/connections',
        name: 'Connections',
        component: Connections,
        props: true
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            allowAnonymous: true
        }
    },
];

const router = new VueRouter({
    mode: 'hash',
    routes
});
router.beforeEach((to, from, next) => {
    if(!to.meta.allowAnonymous && !isLoggedIn()) {
        next({
            path: '/login',
            query: { redirect: to.fullPath }
        });
    } else {
        next();
    }
});
export default router;
