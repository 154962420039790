<template>
    <div id="app">
        <Nav v-if="isLoggedIn()"></Nav>
        <router-view></router-view>
    </div>
</template>

<script>
import Nav from "@/components/Nav";
import {isLoggedIn} from "@/utils/auth";
export default {
    name: 'App',
    components: {
        Nav
    },
    methods: {
        isLoggedIn: function() {
            return isLoggedIn();
        }
    }
}
</script>

<style>
#app {
    /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
    /*-webkit-font-smoothing: antialiased;*/
    /*-moz-osx-font-smoothing: grayscale;*/
    /*text-align: center;*/
    /*color: #2c3e50;*/
    /*margin-top: 60px;*/
}
</style>
