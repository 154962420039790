<template>
  <b-container>
      <b-row>
          <h1>Locaties</h1>
      </b-row>
    <b-row>
      <b-button v-b-modal.new-spot>Nieuwe locatie</b-button>
    </b-row>
    <b-row>
      <b-modal id="new-spot" hide-footer>
        <b-form class="w-100 mx-auto">
          <b-form-group
              id="input-location-code"
              label="Code Locatie">
            <b-form-input
                id="location-code"
                v-model="formNew.code"
                type="text"
                placeholder="Code voor locatie">
            </b-form-input>
          </b-form-group>
          <b-form-group
              id="input-location-color"
              label="Kleur locatie">
            <b-form-input
                id="location-color"
                v-model="formNew.color"
                type="color">
            </b-form-input>
          </b-form-group>
            <b-form-group
                id="input-location-thumb"
                label="Afbeelding Locatie">
                <b-form-input
                    id="location-thumb"
                    v-model="formNew.thumb"
                    type="text"
                    placeholder="Afbeelding voor locatie">
                </b-form-input>
            </b-form-group>
          <b-button @click="newLocation" class="w-50 btn-info">Bewaar</b-button>
        </b-form>

      </b-modal>
    </b-row>
      <b-row>
          <b-table
              id="locationlist"
              fixed
              striped
              hover
              :items="locations"
              :current-page="currentPage"
              :per-page="perPage"
              :fields="fields"
              @row-clicked="onLocationSelect"
          >
          </b-table>
          <b-pagination
              v-model="currentPage"
              :total-rows="locationsLength"
              :per-page="perPage"
              aria-controls="locationlist"
          ></b-pagination>
      </b-row>
  </b-container>
</template>

<script>
    import axios from 'axios';
    import router from '@/router';
    import config from '../../public/config/api.json';
    import {getUserInfo} from "@/utils/auth";
    let configApi = null;
    if(config.is_production) {
        configApi = config.production;
    } else {
        configApi = config.test;
    }

    export default {
        name: "Locations",
        data() {
          return {
              locations: [],
              perPage: 10,
              currentPage: 1,
              locationsLength: 0,
              fields: [
                  {
                      key: 'id',
                      label: 'id'
                  },
                  {
                      key: 'code',
                      label: 'code'
                  },
                  {
                      key: 'description',
                      label: 'omschrijving'
                  }
              ],
              formNew: {
                code: '',
                color: '#000',
                thumb:''
              }
          }
        },
        methods: {
            onLocationSelect: (locationSelected) => {
                let selectedId = locationSelected.id;
                // console.log('id location: ' + selectedId);
                router.push('/location/' + selectedId);
            },
          newLocation() {
            let newData = {
              "code": this.formNew.code,
              "color": this.formNew.color,
              "thumb": this.formNew.thumb
            };
            axios.post(configApi.api_url + 'admin/spot', newData)
                .then((res) => {
                  // console.log(res);
                  // router.push('/locations');
                  if(res) {
                    location.reload();
                  }
                })
          }

        },
        created() {
            let userData = getUserInfo();
            console.log('user.apikey: ' + userData.apikey);
            axios.get(configApi.api_url + 'admin/spots')
            .then((locRes) => {
                let locationsFound = locRes.data;
                locationsFound.forEach(locationFound => {
                    let locationToPush = {
                        "id": locationFound.id,
                        "code": locationFound.code,
                        "description": ''
                    }
                    locationFound.descriptions.forEach((descriptionFound) => {
                        if(descriptionFound.code === 'nl'){
                            locationToPush.description = decodeURI(descriptionFound.name);
                        }
                    });
                    this.locations.push(locationToPush);
                });
                this.locationsLength = this.locations.length;
            })
            .catch();
        }
    }
</script>

<style scoped>

</style>
