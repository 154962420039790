<template>
    <b-container>
        <b-row>
            <b-jumbotron className="w-50 mx-auto mt-2">
                <h1>Welkom!</h1>
            </b-jumbotron>
        </b-row>
    </b-container>
</template>

<script>
import {getUserInfo} from "@/utils/auth";
import router from "@/router";

export default {
    name: "Home",
    methods: {
        getUserInfo: function () {
        }
    },
    created() {
        let userData = getUserInfo();
        if (!userData.fullname) {
            router.push('/login')
        }
        // alert(info);
    }
}
</script>

<style scoped>

</style>
