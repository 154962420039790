<template>
  <b-container>
    <b-row><h1>Thema's</h1></b-row>
    <b-row>
      <b-button v-b-modal.new-theme>Nieuw thema</b-button>
    </b-row>
    <b-row>
      <b-modal id="new-theme" hide-footer>
        <b-form class="w-100 mx-auto">
          <b-form-group
              id="input-theme-name"
              label="Naam thema">
            <b-form-input
                id="theme-name"
                v-model="formNew.name"
                type="text"
                placeholder="Naam thema">
            </b-form-input>
          </b-form-group>
          <b-form-group
              id="input-theme-thumb"
              label="Thumbnail thema">
            <b-form-input
                id="theme-thumb"
                v-model="formNew.thumb"
                type="text">
            </b-form-input>
          </b-form-group>
          <b-button @click="newTheme" class="w-50 btn-info">Bewaar</b-button>
        </b-form>

      </b-modal>
    </b-row>
    <b-row>
      <b-table
          id="themelist"
          fixed
          striped
          hover
          :items="themes"
          :current-page="currentPage"
          :per-page="perPage"
          :fields="fields"
          @row-clicked="onThemeSelect"
      >
      </b-table>
      <b-pagination
          v-model="currentPage"
          :total-rows="themesLength"
          :per-page="perPage"
          aria-controls="themelist"
      ></b-pagination>
    </b-row>
  </b-container>
</template>

<script>
  import axios from 'axios';
  import router from '@/router';
  import config from '../../public/config/api.json';
  import {getUserInfo} from "@/utils/auth";
  let configApi = null;
  if(config.is_production) {
    configApi = config.production;
  } else {
    configApi = config.test;
  }

  export default {
    name: "Themes",
    data() {
      return {
        themes: [],
        perPage: 10,
        currentPage: 1,
        themesLength: 0,
        fields: [
          {
            key: 'id',
            label: 'id'
          },
          {
            key: 'name',
            label: 'naam'
          },
          {
            key: 'thumb',
            label: 'thumb'
          }
        ],
        formNew: {
          name: '',
          thumb: 'DIV/dummy.jpg'
        }
      }
    },
    methods: {
      onThemeSelect: (themeSelected) => {
        let selectedId = themeSelected.id;
        router.push('/theme/' + selectedId);
      },
      newTheme() {
        let newData = {
          "name": this.formNew.name,
          "thumb": this.formNew.thumb
        };
        axios.post(configApi.api_url + 'admin/theme', newData)
            .then((res) => {
              // console.log(res);
              if(res) {
                location.reload();
              }
            })
      }

    },
    created() {
      let userData = getUserInfo();
      console.log('user.apikey: ' + userData.apikey);
      axios.get(configApi.api_url + 'admin/themes')
          .then((themeRes) => {
            let themesFound = themeRes.data;
            themesFound.forEach(themeFound => {
              let themeToPush = {
                "id": themeFound.id,
                "name": themeFound.name,
                "thumb": themeFound.thumb
              }
              this.themes.push(themeToPush);
            });
            this.themesLength = this.themes.length;
          })
          .catch();
    }
  }
</script>

<style scoped>

</style>
