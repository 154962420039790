<template>
    <b-container>
        <b-row>
            <h1>Onderwerpen</h1>
        </b-row>
<!--        <b-row>-->
<!--            <b-button v-b-modal.new-spot>Nieuwe locatie</b-button>-->
<!--        </b-row>-->
        <b-row>
            <b-table
                id="objectlist"
                fixed
                striped
                hover
                :items="objects"
                :current-page="currentPage"
                :per-page="perPage"
                :fields="fields"
                @row-clicked="onObjectSelect"
            >
            </b-table>
            <b-pagination
                v-model="currentPage"
                :total-rows="objectsLength"
                :per-page="perPage"
                aria-controls="objectlist"
            ></b-pagination>
        </b-row>
    </b-container>
</template>

<script>
import axios from 'axios';
import router from '@/router';
import config from '../../public/config/api.json';
import {getUserInfo} from "@/utils/auth";
let configApi = null;
if(config.is_production) {
    configApi = config.production;
} else {
    configApi = config.test;
}
export default {
    name: "Objects",
    data() {
        return {
            objects: [],
            perPage: 10,
            currentPage: 1,
            objectsLength: 0,
            fields: [
                {
                    key: 'id',
                    label: 'id'
                },
                {
                    key: 'objectnr',
                    label: 'Objectnummer'
                },
                {
                    key: 'title',
                    label: 'Titel'
                }
            ]
        }
    },
    methods: {
        onObjectSelect: (objectSelected) => {
            let selectedId = objectSelected.id;
            // console.log('id location: ' + selectedId);
            router.push('/object/' + selectedId);
        }
    },
    created() {
        let userData = getUserInfo();
        console.log('user.apikey: ' + userData.apikey);
        axios.get(configApi.api_url + 'admin/objects')
            .then((objRes) => {
                let objectsFound = objRes.data;
                objectsFound.forEach(objectFound => {
                    let objectTitleDecoded = decodeURI(objectFound.title);
                    if(objectTitleDecoded.length > 30) {
                        objectTitleDecoded = objectTitleDecoded.slice(0, 29) + '...';
                    }
                    let objectToPush = {
                        "id": objectFound.id,
                        "objectnr": objectFound.objectnr,
                        "title": objectTitleDecoded
                    }
                    this.objects.push(objectToPush);
                });
                this.objectsLength = this.objects.length;
            })
            .catch();
    }

}
</script>

<style scoped>

</style>
