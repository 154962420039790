<template>
    <b-container>
        <b-row>
            <b-card class="mx-auto my-4" img-src="/assets/nbm_logo.jpg" style="max-width: 24em" border-variant="white">
                <b-card-body>U kunt zich hier aanmelden voor het beheer van de presentaties.</b-card-body>
            </b-card>
        </b-row>
        <b-row>
            <b-form class="mx-auto my-1" @submit="login">
                <b-form-group
                    label="Uw gebruikersnaam"
                    label-for="login-input-user"
                    id="label-input-user"
                >
                    <b-form-input
                        type="text"
                        v-model="username"
                        id="login-input-user"
                        required
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    label="Uw wachtwoord"
                    label-for="login-input-password"
                    id="label-input-password"
                >
                    <b-form-input
                        type="password"
                        v-model="userpassword"
                        id="login-input-password"
                        required
                    ></b-form-input>
                </b-form-group>
                <b-button class="w-100" type="submit">Login</b-button>
            </b-form>
        </b-row>
    </b-container>
</template>

<script>
import {loginUser} from '@/utils/auth'
import router from '@/router'

export default {
    name: "Login",
    data() {
        return {
            username: '',
            userpassword: ''
        }
    },
    methods: {
        login: async function (evt) {
            evt.preventDefault();
            try {
                await loginUser(this.username, this.userpassword);
                router.push('/');
            } catch (err) {
                alert('Error: ' + err);
            }
        }
    }
}
</script>

<style scoped>

</style>
